import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { useFlexSearch } from "react-use-flexsearch"
import styled, { css } from "styled-components"
import { mix } from "polished"
import {
  Paper,
  Meta,
  MetaSpan,
  MetaActions,
  DraftBadge,
  Tag,
} from "../components/style"
import { ListAuthors } from "../components/authors"

export const SearchBar = ({ index, store }) => {
  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store)

  return (
    <SearchContext>
      <Formik
        initialValues={{ query: "" }}
        onSubmit={(values, { setSubmitting }) => {
          setQuery(values.query)
          setSubmitting(false)
        }}
      >
        <Form>
          <Input name="query" type="search" placeholder="Search" />
        </Form>
      </Formik>
      <SearchResults results={results}></SearchResults>
    </SearchContext>
  )
}
SearchBar.propTypes = {
  index: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}

export const SearchResults = ({ results }) => (
  <div id="search-result">
    {results.length === 0? (
      <Meta>No Result!!!</Meta>
    ) : (
      results.map(item => (
        <Paper article search key={item.id}>
          {item.draft && <DraftBadge>Draft</DraftBadge>}
          <h2>
            <Link to={item.path}>{item.title}</Link>
          </h2>
          {item.tags.map(tag => (
            <Tag href={`/tag/${tag}`}>{tag}</Tag>
          ))}
          <Excerpt>{item.excerpt}</Excerpt>
          <Meta>
            <MetaSpan>{item.date}</MetaSpan>
            {item.authors && (
              <MetaSpan>
                <em>By</em>&nbsp;
                <ListAuthors authorIDs={item.authors} />
              </MetaSpan>
            )}
            <MetaActions>
              <Link to={item.path}>Read Article →</Link>
            </MetaActions>
          </Meta>
        </Paper>
      ))
    )}
  </div>
)
SearchResults.propTypes = {
  results: PropTypes.object.isRequired,
}
export const Excerpt = styled.div`
  margin-top: 2em;
`
export const Input = styled(Field)`
  position: relative;
  line-height: 2.25rem;
  font-size: 1rem;
  padding: 0 0.625rem;
  margin-bottom: 0.5rem;
  border-radius: ${props => props.theme.radius.small};
  border: none;
  width: 100%;
  transition: box-shadow 150ms ${props => props.theme.easing};
  color: ${props => props.theme.color.foreground};
  background-color: ${props =>
    mix(0.95, props.theme.color.background, props.theme.color.foreground)};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.color.secondary};
  }

  ${props =>
    props.theme.isDarkMode &&
    css`
      background-color: ${props => props.theme.color.background};
    `};
`

export const SearchContext = styled.div`
  margin-top: -2rem;
  margin-left: -2rem;
  margin-right: -2rem;
`
